import PropTypes from 'prop-types';

import { Grid } from '@mui/joy';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@mui/material/styles';
import CardHeader from '@mui/material/CardHeader';

import Chart, { useChart } from 'src/components/chart';

// ----------------------------------------------------------------------

export default function SalesEarningChart({
  selectedYear,
  handleYearChange,

  title,
  subheader,
  chart,
  ...other
}) {
  const theme = useTheme();

  const {
    colors = [
      ['#00A76F', ' #00A76F'],
      [theme.palette.primary.light, theme.palette.primary.main],
    ],
    categories,
    series,
    options,
  } = chart;

  const currentYear = new Date().getFullYear();
  const availableYears = Array.from({ length: currentYear - 2019 + 1 }, (_, index) => 2019 + index);
  const chartOptions = useChart({
    colors: colors?.map((colr) => colr[1]),
    fill: {
      type: 'gradient',
      gradient: {
        colorStops: colors?.map((colr) => [
          { offset: 0, color: colr[0], opacity: 1 },
          { offset: 100, color: colr[1], opacity: 1 },
        ]),
      },
    },
    stroke: {
      curve: 'smooth',
    },
    xaxis: {
      categories,
    },
    ...options,
  });

  return (
    <Card {...other}>
      <CardHeader
        title={title}
        subheader={subheader}
        action={
          <Grid item xs={12}>
            <Box sx={{ mb: 2 }}>
              <Select value={selectedYear} onChange={handleYearChange} className="chart-dropdown">
                {availableYears?.map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Grid>
        }
      />

      <Box sx={{ mt: 3, mx: 3 }}>
        <Chart
          dir="ltr"
          type="line"
          series={series.find((item) => item.year === selectedYear)?.data || []}
          options={chartOptions}
          width="100%"
          height={265}
        />
      </Box>
    </Card>
  );
}

SalesEarningChart.propTypes = {
  chart: PropTypes.object,
  subheader: PropTypes.string,
  title: PropTypes.string,
  selectedYear: PropTypes.any,
  handleYearChange: PropTypes.any,
};
