import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createRental,
  deleteRental,
  getAllRentals,
  getRentalDetails,
  getCustomerBalance,
  getRentalExcelData,
  updateRentalDetails,
  getAllOutstandingRentals,
} from '../api/rental';

export const rentalSlice = createSlice({
  name: 'rental',
  initialState: {
    loading: false,
    error: {},
    // staff
    rentals: {},
    rentalBalanceAmounts: {},
    rentalDetails: {},
    customerBalance: {},
    rentalExcelData: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
    clearRentalDetails: (state) => {
      state.rentalDetails = {};
    },
  },
  extraReducers: {
    [createRental.pending]: (state) => {
      state.loading = true;
    },
    [createRental.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.rentals;
      const newRentalList = jsonState?.rentals;

      const modifiedRentalList = {
        ...jsonState,
        rentals: Array.isArray(newRentalList)
          ? [action.payload, ...newRentalList]
          : [action.payload],
      };
      state.loading = false;
      state.rentals = modifiedRentalList;
      state.error = {};
    },
    [createRental.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getAllRentals.pending]: (state) => {
      state.loading = true;
    },
    [getAllRentals.fulfilled]: (state, action) => {
      state.loading = false;
      state.rentals = action.payload;
      state.error = {};
    },
    [getAllRentals.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // new api action start here
    [getAllOutstandingRentals.pending]: (state) => {
      state.loading = true;
    },
    [getAllOutstandingRentals.fulfilled]: (state, action) => {
      state.loading = false;
      state.rentalBalanceAmounts = action.payload;
      state.error = {};
    },
    [getAllOutstandingRentals.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // new api action end here

    [getRentalDetails.pending]: (state) => {
      state.loading = true;
    },
    [getRentalDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.rentalDetails = action.payload;
      state.error = {};
    },
    [getRentalDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [updateRentalDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateRentalDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.rentals;
      const modifiedRentalList = {
        ...jsonState,
        rentals: jsonState.rentals?.map((i) =>
          i._id === action.payload._id ? action?.payload : i
        ),
      };

      state.loading = false;
      state.rentals = modifiedRentalList;
      state.error = {};
    },
    [updateRentalDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deleteRental.pending]: (state) => {
      state.loading = true;
    },
    [deleteRental.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.rentals;
      const modifiedRentalList = {
        ...jsonState,
        rentals: jsonState.rentals?.filter((rental) => rental._id !== action.payload),
      };
      state.loading = false;
      state.rentals = modifiedRentalList;
      state.error = {};
    },
    [deleteRental.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getCustomerBalance.pending]: (state) => {
      state.loading = true;
    },
    [getCustomerBalance.fulfilled]: (state, action) => {
      state.loading = false;
      state.customerBalance = action.payload;
      state.error = {};
    },
    [getCustomerBalance.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getRentalExcelData.pending]: (state) => {
      state.loading = true;
    },
    [getRentalExcelData.fulfilled]: (state, action) => {
      state.loading = false;
      state.rentalExcelData = action.payload;
      state.error = {};
    },
    [getRentalExcelData.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError, clearRentalDetails } = rentalSlice.actions;

export default rentalSlice.reducer;
