// ----------------------------------------------------------------------

const ROOTS = {
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  public: {
    // landing: '/landing',
    login: '/login',
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    profile: `${ROOTS.DASHBOARD}/profile`,
    country: `${ROOTS.DASHBOARD}/country`,
    brand: `${ROOTS.DASHBOARD}/brand`,
    vehicle: `${ROOTS.DASHBOARD}/vehicle`,
    vehicle_view: (id) => `${ROOTS.DASHBOARD}/vehicle/${id}`,
    vehicle_create: `${ROOTS.DASHBOARD}/vehicle/create/new`,
    vehicle_update: (id) => `${ROOTS.DASHBOARD}/vehicle/update/${id}`,
    customer: `${ROOTS.DASHBOARD}/customer`,
    customer_view: (id) => `${ROOTS.DASHBOARD}/customer/${id}`,
    customer_create: `${ROOTS.DASHBOARD}/customer/create/new`,
    customer_update: (id) => `${ROOTS.DASHBOARD}/customer/update/${id}`,
    booking: `${ROOTS.DASHBOARD}/booking`,
    booking_view: (id) => `${ROOTS.DASHBOARD}/booking/${id}`,
    booking_create: `${ROOTS.DASHBOARD}/booking/create/new`,
    booking_update: (id) => `${ROOTS.DASHBOARD}/booking/update/${id}`,
    rental: `${ROOTS.DASHBOARD}/rental`,
    rental_view: (id) => `${ROOTS.DASHBOARD}/rental/${id}`,
    rental_create: `${ROOTS.DASHBOARD}/rental/create/new`,
    rental_update: (id) => `${ROOTS.DASHBOARD}/rental/update/${id}`,
    transaction: `${ROOTS.DASHBOARD}/transaction`,
    expense: `${ROOTS.DASHBOARD}/expense`,
    expense_create: `${ROOTS.DASHBOARD}/expense/create/new`,
    bank: `${ROOTS.DASHBOARD}/bank`,
    income: `${ROOTS.DASHBOARD}/income`,
    income_create: `${ROOTS.DASHBOARD}/income/create/new`,
    balance_sheet: `${ROOTS.DASHBOARD}/balance-sheet`,
    rental_invoice: (id) => `${ROOTS.DASHBOARD}/rental-invoice/${id}`,
    transaction_invoice: (id) => `${ROOTS.DASHBOARD}/transaction-invoice/${id}`,
    invoice_view: (id) => `${ROOTS.DASHBOARD}/invoice-view/${id}`,
    agreement: (id) => `${ROOTS.DASHBOARD}/agreement/${id}`,
  },
};

export const AuthRoles = {
  admin: 'Admin',
  partner: 'Partner',
  school: 'School',
  academy: 'Student',
};
