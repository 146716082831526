// import { useMemo } from 'react';

// import KeyIcon from '@mui/icons-material/Key';
// import GroupIcon from '@mui/icons-material/Group';
// // import PeopleIcon from '@mui/icons-material/People';
// // import HandshakeIcon from '@mui/icons-material/Handshake';
// // import DashboardIcon from '@mui/icons-material/Dashboard';
// import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';
// import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';

// import { paths } from 'src/routes/routes/paths';

// import { useGetRoles } from 'src/hooks/useHandleSessions';

// import Iconify from 'src/components/iconify';
// import SvgColor from 'src/components/svg-color';

// // import { getSidebarItemCountData } from 'src/server/api/dashboard';


// // ----------------------------------------------------------------------

// const icon = (name) => (
//   <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
// );

// // ----------------------------------------------------------------------

// export function useNavData() {
//   const { role } = useGetRoles();

//   // sidebar data block
//   const adminSideBarData = useMemo(
//     () => [
//       {
//         subheader: 'Dashboard',
//         items: [
//           { title: 'Dashboard', path: `${paths.dashboard.root}/app`, icon: icon('ic_dashboard') },
//         ],
//       },
//       {
//         subheader: 'MANAGEMENT',
//         items: [
//           {
//             title: 'Brands',
//             path: paths.dashboard.brand,
//             icon: icon('ic_booking'),
//             // info: <Label color="warning">{counts?.totalEarningsCount}</Label>,
//           },
//           {
//             title: 'Vehicles',
//             path: paths.dashboard.vehicle,
//             icon: <DirectionsCarFilledIcon/>,
//             // info: <Label color="warning">{counts?.totalEarningsCount}</Label>,
//           },
//           {
//             title: 'Customers',
//             path: paths.dashboard.customer,
//             // icon: icon('ic_user'),
//             icon: <GroupIcon />,
//           },
//           {
//             title: 'Rentals',
//             path: paths.dashboard.rental,
//             icon: <KeyIcon />,
//           },
//           {
//             title: 'Bookings',
//             path: paths.dashboard.booking,
//             icon: <ChecklistRtlIcon />,
//           },
//           {
//             title: 'Bank',
//             path: paths.dashboard.bank,
//             icon: icon('ic_banking'),
//           },
//           {
//             title: 'Income',
//             path: paths.dashboard.income,
//             icon: icon('ic_analytics'),
//           },
//           {
//             title: 'Transactions',
//             path: paths.dashboard.transaction,
//             icon: <Iconify icon='grommet-icons:transaction'/>,
//           },
//           {
//             title: 'Expenses',
//             path: paths.dashboard.expense,
//             icon: icon('ic_invoice'),
//           },
//           {
//             title: 'Balance sheet',
//             path: paths.dashboard.balance_sheet,
//             icon: <Iconify icon='bi:file-earmark-spreadsheet-fill'/>,
//           },
//         ],
//       },
//     ],
//     []
//   );
//   // sidebar data block

//   const data = useMemo(() => {
//     const handleSideBarDataWithRole = () => {
//       switch (role) {
//         case 'admin':
//           return adminSideBarData;
//         default:
//           return [];
//       }
//     };

//     return handleSideBarDataWithRole();
//   }, [adminSideBarData, role]);

//   return data;
// }

// // const ICONS = {
// //   job: icon('ic_job'),
// //   blog: icon('ic_blog'),
// //   chat: icon('ic_chat'),
// //   mail: icon('ic_mail'),
// //   user: icon('ic_user'),
// //   file: icon('ic_file'),
// //   lock: icon('ic_lock'),
// //   tour: icon('ic_tour'),
// //   order: icon('ic_order'),
// //   label: icon('ic_label'),
// //   blank: icon('ic_blank'),
// //   kanban: icon('ic_kanban'),
// //   folder: icon('ic_folder'),
// //   banking: icon('ic_banking'),
// //   booking: icon('ic_booking'),
// //   invoice: icon('ic_invoice'),
// //   product: icon('ic_product'),
// //   calendar: icon('ic_calendar'),
// //   disabled: icon('ic_disabled'),
// //   external: icon('ic_external'),
// //   menuItem: icon('ic_menu_item'),
// //   ecommerce: icon('ic_ecommerce'),
// //   analytics: icon('ic_analytics'),
// //   dashboard: icon('ic_dashboard'),
// // };


import { useMemo } from 'react';

import KeyIcon from '@mui/icons-material/Key';
import GroupIcon from '@mui/icons-material/Group';
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';
import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';

import { paths } from 'src/routes/routes/paths';

import { useGetRoles } from 'src/hooks/useHandleSessions';

import Iconify from 'src/components/iconify';
import SvgColor from 'src/components/svg-color';

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

export function useNavData() {
  const { role } = useGetRoles();

  const adminSideBarData = useMemo(
    () => [
      {
        subheader: 'Dashboard',
        items: [
          { title: 'Dashboard', path: `${paths.dashboard.root}/app`, icon: icon('ic_dashboard') },
        ],
      },
      {
        subheader: 'MANAGEMENT',
        items: [
          {
            title: 'Brands',
            path: paths.dashboard.brand,
            icon: icon('ic_booking'),
          },
          {
            title: 'Vehicles',
            path: paths.dashboard.vehicle,
            icon: <DirectionsCarFilledIcon />,
          },
          {
            title: 'Customers',
            path: paths.dashboard.customer,
            icon: <GroupIcon />,
          },
          {
            title: 'Rentals',
            path: paths.dashboard.rental,
            icon: <KeyIcon />,
          },
          {
            title: 'Invoice',
            path: paths.dashboard.transaction,
            icon: <Iconify icon='grommet-icons:transaction' />,
          },
          {
            title: 'Bookings',
            path: paths.dashboard.booking,
            icon: <ChecklistRtlIcon />,
          },
          {
            title: 'Income',
            path: paths.dashboard.income,
            icon: icon('ic_analytics'),
          },
          {
            title: 'Expenses',
            path: paths.dashboard.expense,
            icon: icon('ic_invoice'),
          },
          {
            title: 'Bank',
            path: paths.dashboard.bank,
            icon: icon('ic_banking'),
          },
          {
            title: 'Balance sheet',
            path: paths.dashboard.balance_sheet,
            icon: <Iconify icon='bi:file-earmark-spreadsheet-fill' />,
          },
        ],
      },
    ],
    []
  );

  const staffSideBarData = useMemo(
    () =>
      adminSideBarData.map((section) =>
        section.subheader === 'MANAGEMENT'
          ? {
              ...section,
              items: section.items.filter(
                (item) =>
                  item.title !== 'Bank' && item.title !== 'Income' && item.title !== 'Balance sheet'
              ),
            }
          : section
      ),
    [adminSideBarData]
  );

  const data = useMemo(() => {
    const handleSideBarDataWithRole = () => {
      switch (role) {
        case 'admin':
          return adminSideBarData;
        case 'staff':
          return staffSideBarData;
        default:
          return [];
      }
    };

    return handleSideBarDataWithRole();
  }, [adminSideBarData, staffSideBarData, role]);

  return data;
}
