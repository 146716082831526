import PropTypes from 'prop-types';
import { Tooltip } from 'react-tooltip';

import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import ListItemText from '@mui/material/ListItemText';
// import Avatar from '@mui/material/Avatar';
import { Stack, Avatar, IconButton, Typography } from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import { fDate } from 'src/utils/format-time';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomPopover, { usePopover } from 'src/components/custom-popover';

// ----------------------------------------------------------------------

export default function BookingTableRow({ row, selected, onEditRow, onViewRow, onDeleteRow, customerId }) {
  const { booking_code, customer, booking_amount, rental_start_date, rental_end_date, status, latest_status_updated_date } =
    row;

  const confirm = useBoolean();

  const popover = usePopover();

  const firstLetter = customer?.name?.charAt(0).toUpperCase();

  // const firstLetterVehicle = vehicle?.name?.charAt(0).toUpperCase();

  const handleStatus = (rentalStatus) => {
    switch (rentalStatus) {
      case 'confirmed':
        return 'success';
      case 'scheduled':
        return 'warning';
      case 'expired':
        return 'error';
      default:
        return 'error';
    }
  };

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>{booking_code}</TableCell>
        {!customerId && (
        <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar
            alt={customer?.name}
            src={customer?.image && customer?.image?.length > 0 && customer?.image[0]?.url}
            sx={{ mr: 2 }}
          >
            {firstLetter}
          </Avatar>

          <ListItemText
            primary={customer?.name}
            secondary={customer?.primary_phone}
            primaryTypographyProps={{ typography: 'body2' }}
            secondaryTypographyProps={{
              component: 'span',
              color: 'text.disabled',
            }}
          />
        </TableCell>
        )}

        {/* <TableCell>
          <Stack direction="row" alignItems="center" sx={{ width: 1 }}>
            <Avatar
              variant="rounded"
              alt={vehicle?.name}
              src={vehicle?.images && vehicle?.images?.length > 0 && vehicle?.images[0]?.url}
              sx={{ mr: 2 }}
            >
              {firstLetterVehicle}
            </Avatar>

            <ListItemText
              primary={vehicle?.name}
              secondary={vehicle?.registration_number}
              primaryTypographyProps={{ typography: 'body2' }}
              secondaryTypographyProps={{
                component: 'span',
                color: 'text.disabled',
              }}
            />
          </Stack>
        </TableCell> */}
        <TableCell sx={{ whiteSpace: 'nowrap' }}>{booking_amount}</TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>{fDate(rental_start_date)}</TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>{fDate(rental_end_date)}</TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {/* {status} */}
          <Stack alignItems="start">
          <Label
            variant="soft"
            color={handleStatus(status) || 'success'}
            sx={{ textTransform: 'capitalize' }}
          >
            {status}
          </Label>
          {latest_status_updated_date && (
          <Typography variant='caption' pt={1} color='#8f8f8f'>Last Updated On {fDate(latest_status_updated_date)}</Typography>
          )}
          </Stack>
        </TableCell>

        <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
          <Tooltip title="Quick Edit" placement="top" arrow>
            <IconButton color="default">
              <Iconify icon="solar:pen-bold" />
            </IconButton>
          </Tooltip>

          <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            onViewRow();
            popover.onClose();
          }}
        >
          <Iconify icon="solar:eye-bold" />
          View
        </MenuItem>
        {/* <MenuItem
          onClick={() => {
            onEditRow();
            popover.onClose();
          }}
        >
          <Iconify icon="solar:pen-bold" />
          Edit
        </MenuItem> */}

        <MenuItem
          onClick={() => {
            onDeleteRow();
            popover.onClose();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          Delete
        </MenuItem>
      </CustomPopover>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            Delete
          </Button>
        }
      />
    </>
  );
}

BookingTableRow.propTypes = {
  onDeleteRow: PropTypes.func,
  onEditRow: PropTypes.func,
  onViewRow: PropTypes.func,
  row: PropTypes.object,
  selected: PropTypes.bool,
  customerId: PropTypes.any
};
