import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import { createBrand, deleteBrand, getAllBrands, updateBrandDetails } from '../api/brand';

export const brandSlice = createSlice({
  name: 'brand',
  initialState: {
    loading: false,
    error: {},
    // staff
    brands: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {

    [createBrand.pending]: (state) => {
      state.loading = true;
    },
    [createBrand.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.brands;
      const newBrandList = jsonState?.brands;

      const modifiedBrandList = {
        ...jsonState,
        brands: Array.isArray(newBrandList)
          ? [action.payload, ...newBrandList]
          : [action.payload],
      };
      state.loading = false;
      state.brands = modifiedBrandList;
      state.error = {};
    },
    [createBrand.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getAllBrands.pending]: (state) => {
      state.loading = true;
    },
    [getAllBrands.fulfilled]: (state, action) => {
      state.loading = false;
      state.brands = action.payload;
      state.error = {};
    },
    [getAllBrands.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    
    [updateBrandDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateBrandDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.brands;
      const modifiedBrandList = {
        ...jsonState,
        brands: jsonState.brands?.map((i) =>
          i._id === action.payload._id ? action?.payload : i
        ),
      };

      state.loading = false;
      state.brands = modifiedBrandList;
      state.error = {};
    },
    [updateBrandDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deleteBrand.pending]: (state) => {
      state.loading = true;
    },
    [deleteBrand.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.brands;
      const modifiedBrandList = {
        ...jsonState,
        brands: jsonState.brands?.filter((brand) => brand._id !== action.payload),
      };
      state.loading = false;
      state.brands = modifiedBrandList;
      state.error = {};
    },
    [deleteBrand.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError } = brandSlice.actions;

export default brandSlice.reducer;
