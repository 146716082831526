// import PropTypes from 'prop-types';
// import { useSelector } from 'react-redux';
// import { useTheme } from '@emotion/react';

// import { Stack } from '@mui/material';
// import Grid from '@mui/material/Unstable_Grid2';
// import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';

// import SvgColor from 'src/components/svg-color';

// import BookingsToday from './TodayBookings';
// import RentalExpiration from './RentalExpiration';
// import SalesEarningChart from './sales-earning-chart';
// import AppWidgetSummary2 from './app-widget-summary2';
// import AnalyticsWidgetSummary from './analytics-widget-summary';
// // ----------------------------------------------------------------------

// export default function OverviewBookingView({ setSelectedYear, selectedYear }) {
//   const theme = useTheme();
//   const icon = (name, iconColor) => (
//     <SvgColor
//       src={`/assets/icons/dashboard/${name}.svg`}
//       sx={{ width: 1, height: 1, color: iconColor }}
//     />
//   );
//   const icon2 = (name, iconColor) => (
//     <SvgColor
//       src={`/assets/icons/navbar/${name}.svg`}
//       sx={{ width: 1, height: 1, color: iconColor }}
//     />
//   );

//   const { dashboardData, bookingsChartData } = useSelector((state) => ({
//     dashboardData: state.dashboard.dashboardData,
//     loading: state.dashboard.loading,
//     bookingsChartData: state.dashboard.bookingsChart,
//   }));
//   // const { totalSalesCount, totalSalesValue, totalEarningsValue, totalEarningsCount } =
//   //   dashboardData;
//   // const averageSales = totalSalesValue / totalSalesCount;

//   // const averageEarnings = totalEarningsValue / totalEarningsCount;

//   const handleYearChange = (event) => {
//     const newYear = event.target.value;
//     setSelectedYear(newYear);
//   };

//   return (
//     <>
//     <Grid container spacing={3}>
//       <Grid xs={12} md={6}>
//         <AppWidgetSummary2
//           title="Monthly Revenue"
//           percent={bookingsChartData?.revenueDifferencePercentage}
//           total={`${bookingsChartData?.thisMonthTotalRevenue}`}
//           chart={{
//             colors: [theme.palette.error.light, theme.palette.error.main],
//             series: bookingsChartData?.monthlyRevenue || [],
//           }}
//           unit='AED'
//         />
//       </Grid>

//       <Grid xs={12} md={6}>
//         <AppWidgetSummary2
//           title="Monthly Expense"
//           percent={bookingsChartData?.expenseDifferencePercentage}
//           total={`${bookingsChartData?.thisMonthTotalExpense}`}
//           chart={{
//             colors: [theme.palette.warning.light, theme.palette.warning.main],
//             series: bookingsChartData?.monthlyExpenses || [],
//           }}
//           unit='AED'
//         />
//       </Grid>
//       <Grid xs={12} sm={6} md={3}>
//         <AnalyticsWidgetSummary
//           title="Customers"
//           total={dashboardData?.customerData?.totalCustomersCount || 0}
//           color="error"
//           icon={icon('customer')}
//           // icon={<PeopleAltIcon sx={{width: 1, height: 1}}/>}
//         />
//       </Grid>
//       <Grid xs={12} sm={6} md={3}>
//         <AnalyticsWidgetSummary
//           title="Vehicles"
//           total={dashboardData?.vehicleData?.totalVehicleCount || 0}
//           color="success"
//           // icon={icon('vehicle')}
//           icon={<AirportShuttleIcon sx={{ width: 1, height: 1 }} />}
//         />
//       </Grid>

//       <Grid xs={12} sm={6} md={3}>
//         <AnalyticsWidgetSummary
//           title="This Month Rentals"
//           total={dashboardData?.rentalData?.totalThisMonthRentalsCount || 0}
//           color="info"
//           icon={icon('rental')}
//           // icon={<LabelIcon sx={{width: 1, height: 1}}/>}
//         />
//       </Grid>

//       <Grid xs={12} sm={6} md={3}>
//         <AnalyticsWidgetSummary
//           title="This Month Bookings"
//           total={dashboardData?.bookingData?.totalThisMonthBookingsCount || 0}
//           color="warning"
//           // icon={icon('booking')}
//           icon={icon2('ic_calendar', '#FFAB00')}
//         />
//       </Grid>
//       {bookingsChartData && (
//         <Grid item md={12} xs={12}>
//           <SalesEarningChart
//             selectedYear={selectedYear}
//             handleYearChange={handleYearChange}
//             title="Yearly Revenue & Expense"
//             subheader={`revenue & expense of the year ${selectedYear}`}
//             chart={{
//               categories: [
//                 'Jan',
//                 'Feb',
//                 'Mar',
//                 'Apr',
//                 'May',
//                 'Jun',
//                 'Jul',
//                 'Aug',
//                 'Sep',
//                 'Oct',
//                 'Nov',
//                 'Dec',
//               ],
//               series: [
//                 {
//                   year: selectedYear,
//                   data: [
//                     {
//                       name: 'Total Revenue',
//                       data: bookingsChartData?.monthlyRevenue || [],
//                     },
//                     {
//                       name: 'Total Expense',
//                       data: bookingsChartData?.monthlyExpenses || [],
//                     },
//                   ],
//                 },
//               ],
//             }}
//           />
//         </Grid>
//       )}
//     </Grid>
//       <Stack mt={5}>
//         <RentalExpiration/>
//       </Stack>
//       <Stack mt={5}>
//         <BookingsToday/>
//       </Stack>
//       </>
//   );
// }

// OverviewBookingView.propTypes = {
//   setSelectedYear: PropTypes.any,
//   selectedYear: PropTypes.any,
// };


import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTheme } from '@emotion/react';

import { Stack } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';

import { useGetRoles } from 'src/hooks/useHandleSessions';

import SvgColor from 'src/components/svg-color';

import BookingsToday from './TodayBookings';
import RentalExpiration from './RentalExpiration';
import SalesEarningChart from './sales-earning-chart';
import AppWidgetSummary2 from './app-widget-summary2';
import AnalyticsWidgetSummary from './analytics-widget-summary';
// ----------------------------------------------------------------------

export default function OverviewBookingView({ setSelectedYear, selectedYear }) {
  const theme = useTheme();
  const { role } = useGetRoles();
  const icon = (name, iconColor) => (
    <SvgColor
      src={`/assets/icons/dashboard/${name}.svg`}
      sx={{ width: 1, height: 1, color: iconColor }}
    />
  );
  const icon2 = (name, iconColor) => (
    <SvgColor
      src={`/assets/icons/navbar/${name}.svg`}
      sx={{ width: 1, height: 1, color: iconColor }}
    />
  );

  const { dashboardData, bookingsChartData } = useSelector((state) => ({
    dashboardData: state.dashboard.dashboardData,
    loading: state.dashboard.loading,
    bookingsChartData: state.dashboard.bookingsChart,
  }));

  const handleYearChange = (event) => {
    const newYear = event.target.value;
    setSelectedYear(newYear);
  };

  return (
    <>
      <Grid container spacing={3}>
        {role !== 'staff' && (
          <>
            <Grid xs={12} md={6}>
              <AppWidgetSummary2
                title="Monthly Revenue"
                percent={bookingsChartData?.revenueDifferencePercentage}
                total={`${bookingsChartData?.thisMonthTotalRevenue}`}
                chart={{
                  colors: [theme.palette.error.light, theme.palette.error.main],
                  series: bookingsChartData?.monthlyRevenue || [],
                }}
                unit='AED'
              />
            </Grid>

            <Grid xs={12} md={6}>
              <AppWidgetSummary2
                title="Monthly Expense"
                percent={bookingsChartData?.expenseDifferencePercentage}
                total={`${bookingsChartData?.thisMonthTotalExpense}`}
                chart={{
                  colors: [theme.palette.warning.light, theme.palette.warning.main],
                  series: bookingsChartData?.monthlyExpenses || [],
                }}
                unit='AED'
              />
            </Grid>
          </>
        )}
        
        <Grid xs={12} sm={6} md={3}>
          <AnalyticsWidgetSummary
            title="Customers"
            total={dashboardData?.customerData?.totalCustomersCount || 0}
            color="error"
            icon={icon('customer')}
          />
        </Grid>
        <Grid xs={12} sm={6} md={3}>
          <AnalyticsWidgetSummary
            title="Vehicles"
            total={dashboardData?.vehicleData?.totalVehicleCount || 0}
            color="success"
            icon={<AirportShuttleIcon sx={{ width: 1, height: 1 }} />}
          />
        </Grid>

        <Grid xs={12} sm={6} md={3}>
          <AnalyticsWidgetSummary
            title="This Month Rentals"
            total={dashboardData?.rentalData?.totalThisMonthRentalsCount || 0}
            color="info"
            icon={icon('rental')}
          />
        </Grid>

        <Grid xs={12} sm={6} md={3}>
          <AnalyticsWidgetSummary
            title="This Month Bookings"
            total={dashboardData?.bookingData?.totalThisMonthBookingsCount || 0}
            color="warning"
            icon={icon2('ic_calendar', '#FFAB00')}
          />
        </Grid>
        
        {role !== 'staff' && bookingsChartData && (
          <Grid item md={12} xs={12}>
            <SalesEarningChart
              selectedYear={selectedYear}
              handleYearChange={handleYearChange}
              title="Yearly Revenue & Expense"
              subheader={`revenue & expense of the year ${selectedYear}`}
              chart={{
                categories: [
                  'Jan',
                  'Feb',
                  'Mar',
                  'Apr',
                  'May',
                  'Jun',
                  'Jul',
                  'Aug',
                  'Sep',
                  'Oct',
                  'Nov',
                  'Dec',
                ],
                series: [
                  {
                    year: selectedYear,
                    data: [
                      {
                        name: 'Total Revenue',
                        data: bookingsChartData?.monthlyRevenue || [],
                      },
                      {
                        name: 'Total Expense',
                        data: bookingsChartData?.monthlyExpenses || [],
                      },
                    ],
                  },
                ],
              }}
            />
          </Grid>
        )}
      </Grid>
      <Stack mt={5}>
        <RentalExpiration />
      </Stack>
      <Stack mt={5}>
        <BookingsToday />
      </Stack>
    </>
  );
}

OverviewBookingView.propTypes = {
  setSelectedYear: PropTypes.any,
  selectedYear: PropTypes.any,
};
