import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// @mui
import {
  Card,
  Paper,
  Table,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  CircularProgress,
} from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import { deleteBooking, getAllBookings } from 'src/server/api/booking';

import Scrollbar from 'src/components/scrollbar';
import {
  useTable,
  TableNoData,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from 'src/components/table';

import BookingTableRow from 'src/sections/Booking/BookingTableRow';
import DeleteConfirmDialog from 'src/sections/common/DeleteConfirm';
// sections

export default function BookingsToday() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const table = useTable({ defaultOrderBy: 'createdAt', defaultRowsPerPage: 5 });

  const { bookings, loading } = useSelector((state) => ({
    bookings: state.booking.bookings,
    loading: state.booking.loading,
  }));

  const [customerData, setCustomerData] = useState({});
  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false);
  const item = {
    heading: 'Are you sure you want to delete this customer? This action cannot be undone.',
  };

  const [tableData, setTableData] = useState([]);
  const canReset = false;
  const notFound = (!tableData?.length && canReset) || !tableData?.length;
  const identifier = 'booking';
  const storedRowsPerPage = localStorage.getItem(`rowsPerPage_${identifier}`);
  const total = bookings?.total;
  const totalVehicles = Math.ceil(total / table.rowsPerPage);

  const TABLE_HEAD = [
    { id: 'booking_code', label: 'Booking Code' },
    { id: 'customer', label: 'Customer' },
    { id: 'amount', label: 'Booking Amount' },
    { id: 'rental_start_date', label: 'Rental Start Date' },
    { id: 'rental_end_date', label: 'Rental End Date' },
    { id: 'status', label: 'Status' },
    { id: '', label: '' },
  ];

  useEffect(() => {
    setTableData(bookings?.bookings);
  }, [bookings]);

  useEffect(() => {
    const credentials = {
      page: table.page + 1,
      search: '',
      dispatch,
      limit: table.rowsPerPage || storedRowsPerPage,
      sort: 'today',
    };
    dispatch(getAllBookings(credentials));
  }, [dispatch, table.page, table.rowsPerPage, storedRowsPerPage]);

  const handleEditRow = (data) => {
    navigate(paths.dashboard.booking_update(data?._id));
  };

  const handleViewRow = (id) => {
    navigate(paths.dashboard.booking_view(id));
  };

  const handleDeleteClick = (customer) => {
    setCustomerData(customer);
    setOpenDeleteConfirmDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteConfirmDialog(false);
  };

  const handleDeleteConfirmClick = () => {
    const credentials = {
      dispatch,
      customerId: customerData?._id,
      handleCloseDeleteDialog,
    };
    dispatch(deleteBooking(credentials));
  };

  return (
    <>
      <DeleteConfirmDialog
        onConfirmClick={handleDeleteConfirmClick}
        setOpen={setOpenDeleteConfirmDialog}
        open={openDeleteConfirmDialog}
        item={item}
        loading={loading}
        title={`Customer "${customerData?.name}"`}
      />
      <Card>
        <Typography p={2} variant="h5">{`Today's Bookings`}</Typography>
        <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
          <TableSelectedAction
            dense={table.dense}
            numSelected={table.selected?.length}
            rowCount={tableData?.length}
            onSelectAllRows={(checked) => {
              table.onSelectAllRows(
                checked,
                tableData.map((row) => row.id)
              );
            }}
          />
          <Scrollbar>
            <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
              <TableHeadCustom
                order={table.order}
                orderBy={table.orderBy}
                headLabel={TABLE_HEAD}
                rowCount={tableData?.length}
                numSelected={table.selected?.length}
                onSort={table.onSort}
              />

              <TableBody>
                {loading ? (
                  <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                    <Paper
                      sx={{
                        textAlign: 'center',
                      }}
                    >
                      <CircularProgress color="inherit" />
                    </Paper>
                  </TableCell>
                ) : (
                  <>
                    {(tableData || []).map((row, index) => (
                      <BookingTableRow
                        index={index}
                        page={table.page + 1}
                        rowsPerPage={table.rowsPerPage || 5}
                        key={row.id}
                        row={row}
                        selected={table?.selected?.includes(row.id)}
                        onSelectRow={() => table.onSelectRow(row.id)}
                        onViewRow={() => handleViewRow(row._id)}
                        onEditRow={() => handleEditRow(row)}
                        onDeleteRow={() => handleDeleteClick(row)}
                        loading={loading}
                        colSpan={TABLE_HEAD?.length}
                      />
                    ))}
                  </>
                )}
              </TableBody>

              {/* <TableEmptyRows
                  height={denseHeight}
                  emptyRows={emptyRows(table.page, table.rowsPerPage, tableData?.length)}
                />  */}

              <TableNoData notFound={notFound} />
            </Table>
          </Scrollbar>
        </TableContainer>

        <TablePaginationCustom
          count={totalVehicles || 0}
          page={table.page}
          rowsPerPage={table.rowsPerPage || storedRowsPerPage || 5}
          onPageChange={table.onChangePage}
          onRowsPerPageChange={table.onChangeRowsPerPage}
          dense={table.dense}
          onChangeDense={table.onChangeDense}
        />
      </Card>
    </>
  );
}
